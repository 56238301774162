<template>
	<div id="app">
		<new-nav :class="$route.name !== 'home' ? 'bg-gradient-to-tr from-color-1 to-color-2' : ''" />
		<router-view />
		<app-footer />
	</div>
</template>

<script>
const events = require('./util/onChainListener');

import NewNav from './components/NewNav.vue';
import AppFooter from './components/AppFooter.vue';
export default {
	name: 'App',
	components: { NewNav, AppFooter },
	mounted() {
		events;
	},
};
</script>

<style>
.WEB3_CONNECT_MODAL_ID {
	position: absolute !important;
	z-index: 1000 !important;
}
.-walletlink-css-reset .-walletlink-extension-dialog-box-top-description-icon-wrapper {
	display: none !important;
	position: relative;
	width: 40 px;
	height: 40 px;
	flex-shrink: 0;
	flex-grow: 0;
	border-radius: 20 px;
	background-color: #fff;
	box-shadow: 0px 0px 8px rgb(0 0 0 / 4%), 0px 16px 24px rgb(0 0 0 / 6%);
}
</style>
