import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./assets/css/main.css";

import VueTailwindModal from "vue-tailwind-modal"
Vue.component("VueTailwindModal", VueTailwindModal)


Vue.use(Toast, {
  timeout: 2000
});

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount("#app");
